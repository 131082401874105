export const store = {
    clients: {
        data: []
    },
    newClient: {
        data: []
    },
    repuestos: {
        data: []
    },
    newRepuesto: {
        data: []
    },
    tiposDeRepuestos: {
        data: []
    },
}