import { actionTypes } from "../Constants/ActionTypes";
import { store } from "../Store";


export default function reducerSetNewRepuesto(state = store.newRepuesto, action){
    switch (action.type) {
        case actionTypes.SET_NEW_REPUESTO:
            let repuesto = action.data
            return Object.assign({}, state, {
            data: repuesto,
            })
        default:
            return state
    }
}