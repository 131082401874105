import { actionTypes } from "../Constants/ActionTypes";

const initialState = {
    resPrecioDeCompra: null
}

export default function reducerResPrecioDeCompra(state = initialState, action){
    switch (action.type) {
      case actionTypes.RES_CAMBIAR_PRECIO_COMPRA:
        return {
          ...state,
          resPrecioDeCompra: action.data
      }
      case actionTypes.EMPTY_CAMBIAR_PRECIO_COMPRA:
        return {
          ...state,
          resPrecioDeCompra: null
      }
      default:
        return state;
    }
}