import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {
    BrowserRouter,
    Routes,
    Route,
  } from "react-router-dom";
import NavBar from './Component/NavBar/NavBar';
import Inicio from './Pages/Inicio/Inicio';
import Presupuestos from './Pages/Presupuestos/Presupuestos';
import Repuestos from './Pages/Repuestos/Repuestos';
import OrdenesDeTrabajo from './Pages/OrdenesDeTrabajo/OrdenesDeTrabajo';
import { actionGetClients } from './Redux/Actions/Cliente';
import { actionGetRepuestos } from './Redux/Actions/Repuesto';
import { actionGetTiposDeRepuestos } from './Redux/Actions/TipoDeRepuesto';
import { actionGetTiposDeMarcas } from './Redux/Actions/TipoDeMarca';
import { actionGetTiposDeModelos } from './Redux/Actions/TipoDeModelo';


export default function Rutas() {

  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const resAddNewRepuesto = useSelector((state) => state.reducerSetNewRepuesto.data)
  const resAddNewClient = useSelector((state) => state.reducerSetNewClient.data)
  const resActionGetRepuestos = useSelector((state) => state.reducerSetRepuestos.data)
  const resActionGetClients = useSelector((state) => state.reducerSetClients.data)
  const resActionGetTipoDeRepuestos = useSelector((state) => state.reducerSetTiposDeRepuestos.data)
  const resActionGetTiposDeModelos = useSelector((state) => state.reducerSetTiposDeModelos.tiposDeModelo)
  const resActionGetTiposDeMarcas = useSelector((state) => state.reducerSetTiposDeMarcas.tiposDeMarca)

  useEffect(() => {
    dispatch(actionGetRepuestos())
    dispatch(actionGetClients())
    dispatch(actionGetTiposDeRepuestos())
    dispatch(actionGetTiposDeMarcas())
    dispatch(actionGetTiposDeModelos())
  }, [resAddNewRepuesto, resAddNewClient, dispatch])

  useEffect(()=> {
    if(resActionGetRepuestos && resActionGetTipoDeRepuestos && resActionGetTiposDeModelos && resActionGetTiposDeMarcas && resActionGetClients) {
      setLoading(false)
    }
  }, [resActionGetRepuestos, resActionGetTipoDeRepuestos, resActionGetTiposDeModelos, resActionGetTiposDeMarcas, resActionGetClients])

  
function ProtectedRoutes() {
  return (
    <div className='containerRouts'>
      <NavBar />
      <Routes>
        <Route path="/" element={<Inicio/>} />
        <Route path="repuestos" element={<Repuestos/>} />
        <Route path="presupuesto" element={<Presupuestos/>} />
        <Route path="ordenesDeTrabajo" element={<OrdenesDeTrabajo/>} />
      </Routes>
    </div>
  );
}

if (loading) {
  return <div className='containerLoader'><div className="loader"></div></div>
}

return (
    
    <>
    <BrowserRouter>
          <Routes>
            <Route path="/8Lcx6g$!9$!TMM9b/*" element={<ProtectedRoutes/>} />
          </Routes>
    </BrowserRouter>
    </>
  )
}
