import { actionTypes } from "../Constants/ActionTypes";

export const actionGetTiposDeMarcas = () => {
    return {
        type: actionTypes.GET_TIPOS_DE_MARCAS
    }
}

export const actionSetTiposDeMarcas = (data) => {
    return {
        type: actionTypes.SET_TIPOS_DE_MARCAS,
        data
    }
}