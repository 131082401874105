import {all} from "redux-saga/effects"
import { watchAddNewClient } from "./watchAddNewClient"
import { watchAddNewRepuesto } from "./watchAddNewRepuesto"
import { watchGetClients } from "./watchGetClients"
import { watchGetRepuestos } from "./watchGetRepuestos"
import { watchTiposDeRepuestos } from "./watchTiposDeRepuestos"
import { watchTiposDeMarcas } from "./watchTiposDeMarcas"
import { watchTiposDeModelos } from "./watchTiposDeModelos"
import { watchPutPrecioDeCompra } from "./watchPutPrecioDeCompra"
import { watchPutPrecioDeLista } from "./watchPutPrecioDeLista"

export default function * rootSagas() {
    yield all([
        watchGetClients(),
        watchAddNewClient(),
        watchGetRepuestos(),
        watchAddNewRepuesto(),
        watchTiposDeRepuestos(),
        watchTiposDeMarcas(),
        watchTiposDeModelos(),
        watchPutPrecioDeCompra(),
        watchPutPrecioDeLista(),
    ])
}