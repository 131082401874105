import { actionTypes } from "../Constants/ActionTypes";

export const actionCambiarPrecioDeCompra = (id, precioCompra) => {
    return {
        type: actionTypes.PUT_CAMBIAR_PRECIO_COMPRA,
        id,
        precioCompra
    }
}

export const actionResCambiarPrecioDeCompra = (data) => {
    return {
        type: actionTypes.RES_CAMBIAR_PRECIO_COMPRA,
        data
    }
}

export const actionEmptyCambiarPrecioDeCompra = () => {
    return {
        type: actionTypes.EMPTY_CAMBIAR_PRECIO_COMPRA
    }
}