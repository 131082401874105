import { actionTypes } from '../Constants/ActionTypes';
import { takeEvery, call, put } from'redux-saga/effects';
import { postAddNewClient } from'../api/postAddNewClient';
import { setNewClient } from '../Actions/Cliente';


export const watchAddNewClient = function * (){
    yield takeEvery(actionTypes.ADD_NEW_CLIENT, fetchAddNewClient)
}

function* fetchAddNewClient(action){
    try{
        const data = yield call(postAddNewClient, action.data)
        if(data){
            yield put(setNewClient(data))
        }
    }catch(err){
        console.log(err)
    }
}
