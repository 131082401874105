import { useSelector } from "react-redux";
import Header from "../../Component/Header/Header";
import TableRepuestos from "../../Component/TableRepuestos/TableRepuestos";

export default function Repuestos() {
  const header = ["Tipo", "Descripción", "Precio de lista", "Precio de compra"];
  const repuestos = useSelector(state => state.reducerSetRepuestos.data)
  

  return (
    <>
      <div className="containerInicio">
        <Header/>
        <div className="bodyInicio">
          <TableRepuestos
           header={header}
           data={repuestos}
           placeholder={"Búsqueda por repuesto"}
           filterKey={"tipo"}
          />
        </div>
      </div>
    </>
  );
}
