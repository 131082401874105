import { actionTypes } from "../Constants/ActionTypes";

const initialState = {
    tiposDeMarca: []
}

export default function reducerSetTiposDeMarcas(state = initialState, action){
    switch (action.type) {
      case actionTypes.SET_TIPOS_DE_MARCAS:
        return {
          ...state,
          tiposDeMarca: action.data
      }
      default:
        return state;
    }
}