import { actionTypes } from '../Constants/ActionTypes';
import { takeEvery, call, put } from'redux-saga/effects';
import { putCambiarPrecioDeCompra } from '../api/putCambiarPrecioDeCompra';
import { actionResCambiarPrecioDeCompra } from '../Actions/CambiarPrecioCompra';


export const watchPutPrecioDeCompra = function * (){
    yield takeEvery(actionTypes.PUT_CAMBIAR_PRECIO_COMPRA, fetchPutPrecioDeCompra)
}

function* fetchPutPrecioDeCompra(action){
    try{
        const data = yield call(putCambiarPrecioDeCompra, action.id, action.precioCompra)
        if(data){
            yield put(actionResCambiarPrecioDeCompra(data))
        }
    }catch(err){
        console.log(err)
    }
}
