import React, { useEffect, useState } from "react";
import ButtonAdd from "../ButtonAdd/ButtonAdd";
import ModalAddNewRepuesto from "../ModalAddNewRepuesto/ModalAddNewRepuesto";
import Lupa from "../../Assets/Header/Lupa.svg";
import { TfiPencilAlt } from "react-icons/tfi";
import { useDispatch, useSelector } from "react-redux";
import useCurrencyMaskForItems from "../../Hooks/useCurrencyMaskForItems";
import { actionCambiarPrecioDeCompra, actionEmptyCambiarPrecioDeCompra } from "../../Redux/Actions/CambiarPrecioCompra";
import { actionCambiarPrecioDeLista, actionEmptyCambiarPrecioDeLista } from "../../Redux/Actions/CambiarPrecioLista";
import { actionGetRepuestos } from "../../Redux/Actions/Repuesto";

const TableRepuestos = ({ header, data, placeholder, filterKey }) => {
  const [open, setOpen] = useState();
  const [searchTerm, setSearchTerm] = useState("");
  const [cambiarPrecioLista, setCambiarPrecioLista] = useState({});
  const [cambiarPrecioCompra, setCambiarPrecioCompra] = useState({});
  const [preciosCompra, currencyMaskPrecioCompra] = useCurrencyMaskForItems();
  const [preciosLista, currencyMaskPrecioLista] = useCurrencyMaskForItems();
  const resPrecioDeCompra = useSelector(
    (state) => state.reducerResPrecioDeCompra.resPrecioDeCompra
  );
  const resPrecioDeLista = useSelector(
    (state) => state.reducerResPrecioDeLista.resPrecioDeLista
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (resPrecioDeCompra) {
      dispatch(actionGetRepuestos());
      dispatch(actionEmptyCambiarPrecioDeCompra())
      setCambiarPrecioCompra({})
    }else if (resPrecioDeLista) {
      dispatch(actionGetRepuestos());
      dispatch(actionEmptyCambiarPrecioDeLista())
      setCambiarPrecioLista({})
    }
  }, [resPrecioDeCompra, resPrecioDeLista]);

  const haddleClickTrue = () => {
    setOpen(true);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const filterArray = (data, filterKey, searchTerm) => {
    return data.filter((dato) =>
      dato[filterKey].toLowerCase().includes(searchTerm.toLowerCase())
    );
  };

  const filteredData = filterArray(data, filterKey, searchTerm);

  const handleCambiarPrecioLista = (id) => {
    setCambiarPrecioLista((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const handleCambiarPrecioCompra = (id) => {
    setCambiarPrecioCompra((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const onSubmitPrecioCompra = (e, itemId) => {
    e.preventDefault();
    dispatch(actionCambiarPrecioDeCompra(itemId, preciosCompra[itemId]));
  };

  const onSubmitPrecioLista = (e, itemId) => {
    e.preventDefault();
    dispatch(actionCambiarPrecioDeLista(itemId, preciosLista[itemId]));
  };

  return (
    <>
      <div className="containerHeaderTableClients">
        <div className="containerSearch">
          <input
            onChange={handleSearch}
            type="text"
            placeholder={placeholder}
            className="inputSearch"
          />
          <img className="imageSearch" src={Lupa} alt={Lupa} />
        </div>
        <ButtonAdd
          content={"Agregar nuevo repuesto"}
          onClick={haddleClickTrue}
        />
      </div>
      <table className="table">
        <thead className="thead">
          <tr className="tr">
            {header.map((header) => (
              <th className="th" key={header}>
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="tbody">
          {filteredData.map((item) => (
            <tr className="tr" key={item.id}>
              <td className="td">{item.tipo}</td>
              <td className="td">{item.descripcion}</td>
              <td className="tdPrecioCompra">
                {cambiarPrecioLista[item.id] === true ? (
                  <form
                    onSubmit={(e) => onSubmitPrecioLista(e, item.id)}
                    className="formCambioDePrecio"
                  >
                    <input
                      className="inputCambioDePrecio"
                      name="precioLista"
                      type="text"
                      value={preciosLista[item.id] || ""}
                      onChange={(e) => currencyMaskPrecioLista(e, item.id)}
                    />
                    <button className="my-button-cambiarDePrecio">
                      Enviar
                    </button>
                    <TfiPencilAlt
                      className="editPrecio"
                      onClick={() => {
                        handleCambiarPrecioLista(item.id);
                      }}
                    />
                  </form>
                ) : (
                  <>
                    <p>{parseFloat(item.precioLista).toLocaleString()}</p>
                    <TfiPencilAlt
                      className="editPrecio"
                      onClick={() => {
                        handleCambiarPrecioLista(item.id);
                      }}
                    />
                  </>
                )}
              </td>
              <td className="tdPrecioCompra">
                {cambiarPrecioCompra[item.id] === true ? (
                  <form
                    onSubmit={(e) => onSubmitPrecioCompra(e, item.id)}
                    className="formCambioDePrecio"
                  >
                    <input
                      className="inputCambioDePrecio"
                      name="precioCompra"
                      type="text"
                      value={preciosCompra[item.id] || ""}
                      onChange={(e) => currencyMaskPrecioCompra(e, item.id)}
                    />
                    <button className="my-button-cambiarDePrecio">
                      Enviar
                    </button>
                    <TfiPencilAlt
                      className="editPrecio"
                      onClick={() => handleCambiarPrecioCompra(item.id)}
                    />
                  </form>
                ) : (
                  <>
                    <p>{parseFloat(item.precioCompra).toLocaleString()}</p>
                    <TfiPencilAlt
                      className="editPrecio"
                      onClick={() => {
                        handleCambiarPrecioCompra(item.id);
                      }}
                    />
                  </>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <ModalAddNewRepuesto setOpen={setOpen} open={open} />
    </>
  );
};

export default TableRepuestos;
