import React, { useState } from "react";
import ModalAddNewClient from "../ModalAddNewClient/ModalAddNewClient";
import ButtonAdd from "../ButtonAdd/ButtonAdd";
import Lupa from "../../Assets/Header/Lupa.svg"
import "./tableClients.css";

const TableClients = ({ header, data, placeholder, filterKey }) => {
  const [open, setOpen] = useState();
  const [searchTerm, setSearchTerm] = useState('');
  
  const haddleClickTrue = () => {
    setOpen(true);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const filterArray = (data, filterKey, searchTerm) => {
    return data.filter((dato) =>
      dato[filterKey].toLowerCase().includes(searchTerm.toLowerCase())
    );
  };

  const filteredData = filterArray(data, filterKey, searchTerm);

  return (
    <>
      <div className="containerHeaderTableClients">
      <div className="containerSearch">
        <input
          onChange={handleSearch}
          type="text"
          placeholder={placeholder}
          className="inputSearch"
        />
        <img className="imageSearch" src={Lupa} alt={Lupa}/>
      </div>
      <ButtonAdd content={"Agregar nuevo cliente"} onClick={haddleClickTrue} />
      </div>
      <table className="table">
        <thead className="thead">
          <tr className="tr">
            {header.map((header) => (
              <th className="th" key={header}>
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="tbody">
          {filteredData.map((item) => (
            <tr className="tr" key={item.id}>
              <td className="td">{item.patente}</td>
              <td className="td">
                {item.marca.charAt(0).toUpperCase() + item.marca.slice(1)}
              </td>
              <td className="td">
                {item.modelo.charAt(0).toUpperCase() + item.modelo.slice(1)}
              </td>
              <td className="td">{item.año}</td>
              <td className="td">
                {item.nombreCliente.charAt(0).toUpperCase() +
                  item.nombreCliente.slice(1)}{" "}
                {item.apellidoCliente.charAt(0).toUpperCase() +
                  item.apellidoCliente.slice(1)}
              </td>
              <td className="td">{item.telefono}</td>
              <td className="td">{item.email}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <ModalAddNewClient setOpen={setOpen} open={open} />
    </>
  );
};

export default TableClients;
