export const actionTypes = {
    //CLIENTES
    GET_CLIENTS: "GET_CLIENTS",
    SET_CLIENTS: "SET_CLIENTS",
    //ADD NEW VEHICULO
    ADD_NEW_CLIENT: "ADD_NEW_CLIENT",
    SET_NEW_CLIENT: "SET_NEW_CLIENT",
    //REPUESTOS
    GET_REPUESTOS: "GET_REPUESTOS",
    SET_REPUESTOS: "SET_REPUESTOS",
    //ADD NEW REPUESTOS
    ADD_NEW_REPUESTO: "ADD_NEW_REPUESTO",
    SET_NEW_REPUESTO: "SET_NEW_REPUESTO",
    //GET y SET TIPOS DE REPUESTOS
    GET_TIPOS_DE_REPUESTOS: "GET_TIPOS_DE_REPUESTOS",
    SET_TIPOS_DE_REPUESTOS: "SET_TIPOS_DE_REPUESTOS",
    //GET Y SET MARCA
    GET_TIPOS_DE_MARCAS: "GET_TIPOS_DE_MARCAS",
    SET_TIPOS_DE_MARCAS: "SET_TIPOS_DE_MARCAS",
    //GET Y SET MODELO
    GET_TIPOS_DE_MODELOS: "GET_TIPOS_DE_MODELOS",
    SET_TIPOS_DE_MODELOS: "SET_TIPOS_DE_MODELOS",
    //PUT Y RES CAMBIAR PRECIO DE COMPRA
    PUT_CAMBIAR_PRECIO_COMPRA: "PUT_CAMBIAR_PRECIO_COMPRA",
    RES_CAMBIAR_PRECIO_COMPRA: "RES_CAMBIAR_PRECIO_COMPRA",
    EMPTY_CAMBIAR_PRECIO_COMPRA: "EMPTY_CAMBIAR_PRECIO_COMPRA",
    //PUT Y RES CAMBIAR PRECIO LISTA
    PUT_CAMBIAR_PRECIO_LISTA: "PUT_CAMBIAR_PRECIO_LISTA",
    RES_CAMBIAR_PRECIO_LISTA: "RES_CAMBIAR_PRECIO_LISTA",
    EMPTY_CAMBIAR_PRECIO_LISTA: "EMPTY_CAMBIAR_PRECIO_LISTA",
}