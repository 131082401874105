import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import "animate.css";
import { addNewRepuesto } from "../../Redux/Actions/Repuesto";
import useCurrencyMaskForText from "../../Hooks/useCurrencyMaskForText";
import "./modalAddNewRepuesto.css";
import { FcPlus, FcMinus } from "react-icons/fc";

export default function ModalAddNewRepuesto({ open, setOpen }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
  } = useForm();
  const [typeExists, setTypeExists] = useState(false);
  const [isCreatingNewType, setIsCreatingNewType] = useState(false);
  const [loading, setLoading] = useState();

  const dispatch = useDispatch();
  const resAddNewRepuesto = useSelector(
    (state) => state.reducerSetNewRepuesto.data
  );
  const tiposDeRepuestos = useSelector(
    (state) => state.reducerSetTiposDeRepuestos.data
  );
  const [precioCompra, currencyMaskPrecioCompra] = useCurrencyMaskForText();
  const [precioLista, currencyMaskPrecioLista] = useCurrencyMaskForText();

  const convertCurrencyToNumber = (currency) => {
    const numberValue = currency.replace(/\./g, "");
    return parseInt(numberValue, 10);
  };

  const precioCompraNumber = convertCurrencyToNumber(precioCompra);
  const precioListaNumber = convertCurrencyToNumber(precioLista);

  const onSubmit = (data) => {
    setLoading(true);
    dispatch(addNewRepuesto(data));
  };

  const selectedType = watch("type");

  useEffect(() => {
    setTypeExists(tiposDeRepuestos.includes(selectedType));
  }, [selectedType, tiposDeRepuestos]);

  useEffect(() => {
    if (resAddNewRepuesto.message === "Inserted") {
      currencyMaskPrecioCompra({ target: { value: "" } });
      currencyMaskPrecioLista({ target: { value: "" } });
      reset();
      setLoading(false);
      setOpen(false);
    }
  }, [
    resAddNewRepuesto,
    reset,
    setOpen,
    currencyMaskPrecioCompra,
    currencyMaskPrecioLista,
  ]);

  const toggleInputType = () => {
    setIsCreatingNewType(!isCreatingNewType);
  };

  useEffect(() => {
    register("precioCompra", { required: true });
    register("precioLista", { required: true });
  }, [register]);

  useEffect(() => {
    setValue("precioCompra", precioCompraNumber);
    setValue("precioLista", precioListaNumber);
  }, [precioCompraNumber, precioListaNumber, setValue]);

  return (
    <>
      {open && (
        <div className={`modal ${open ? "is-active" : ""}`}>
          <div className="modal-card animate__jackInTheBox">
            <header className="modal-card-head">
              <p className="modal-card-title">Añadir nuevo repuesto</p>
              <button
                className="close-button "
                onClick={() => setOpen(false)}
              ></button>
            </header>
            <section className="modal-card-body">
              <form onSubmit={handleSubmit(onSubmit)} className="form">
                <div className="form-group">
                  <label className="label">Tipo</label>
                  <div className="containerTipoDeRepuesto">
                    {isCreatingNewType ? (
                      <input
                        className="input"
                        name="tipo"
                        type="text"
                        {...register("tipo", { required: true })}
                      />
                    ) : (
                      <select
                        className="input selectPresupuesto selectPresupuestoAddRepuesto"
                        name="tipo"
                        {...register("tipo", { required: true })}
                      >
                        <option value="">Selecciona un tipo</option>
                        {tiposDeRepuestos.map((type) => (
                          <option key={type} value={type}>
                            {type}
                          </option>
                        ))}
                      </select>
                    )}
                    {isCreatingNewType ? (
                      <div className="tooltip">
                        <FcMinus
                          onClick={toggleInputType}
                          className="fcMinus"
                        />
                        <span className="tooltip-text">
                          Seleccionar tipo de repuesto
                        </span>
                      </div>
                    ) : (
                      <div className="tooltip">
                        <FcPlus onClick={toggleInputType} className="fcMinus" />
                        <span className="tooltip-text">
                          Agregar tipo de repuesto
                        </span>
                      </div>
                    )}
                    {errors.tipo?.type === "required" && (
                      <p className="requiered">El tipo es requerido.</p>
                    )}
                  </div>

                  {typeExists && !isCreatingNewType && (
                    <p>El tipo ya existe, selecciona otro.</p>
                  )}
                </div>
                <div className="form-group">
                  <label className="label">Descripción</label>
                  <input
                    className="input"
                    name="descripcion"
                    type="text"
                    {...register("descripcion", { required: true })}
                  />
                  {errors.descripcion?.type === "required" && (
                    <p className="requiered">La descripción es requerida.</p>
                  )}
                </div>

                <div className="form-group">
                  <label className="label">Precio de compra</label>
                  <input
                    className="input"
                    name="precioCompra"
                    type="text"
                    value={precioCompra}
                    onChange={currencyMaskPrecioCompra}
                  />
                  {errors.precioCompra?.type === "required" && (
                    <p className="requiered">
                      El precio de compra es requerido.
                    </p>
                  )}
                </div>

                <div className="form-group">
                  <label className="label">Precio de lista</label>
                  <input
                    className="input"
                    name="precioLista"
                    type="text"
                    value={precioLista}
                    onChange={currencyMaskPrecioLista}
                  />
                  {errors.precioLista?.type === "required" && (
                    <p className="requiered">
                      El precio de lista es requerido.
                    </p>
                  )}
                </div>
                <footer className="modal-card-foot">
                  <div className="containerButtons">
                    {loading ? (
                      <button
                      type="submit"
                      className={`my-button ${loading ? 'loading' : ''}`}
                      disabled={typeExists && !isCreatingNewType}
                    >
                    </button>
                    ) : (
                      <button
                        type="submit"
                        className="my-button"
                        disabled={typeExists && !isCreatingNewType}
                      >
                        Guardar
                      </button>
                    )}

                    <div
                      className="cancel-button"
                      onClick={() => setOpen(false)}
                    >
                      Cancelar
                    </div>
                  </div>
                </footer>
              </form>
            </section>
          </div>
        </div>
      )}
    </>
  );
}
