import { useSelector } from "react-redux";
import "./presupuesto.css";
import { useForm } from "react-hook-form";
import iconMarca from "../../Assets/NavBar/iconMarca.png";
import impresora from "../../Assets/Presupuestos/Impresora.png";
import { useEffect, useState } from "react";
import { FcPlus } from "react-icons/fc";
import { FaMinus } from "react-icons/fa";
import useCurrencyMask from "../../Hooks/useCurrencyMask";
import { PDFDownloadLink } from "@react-pdf/renderer";
import PdfPresupuestos from "./PdfPresupuestos/PdfPresupuestos";

export default function Presupuestos() {
  const fecha = new Date();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm();
  const currencyMask = useCurrencyMask();
  const [dataForm, setDataForm] = useState([]);
  const repuestos = useSelector((state) => state.reducerSetRepuestos.data);
  const [componentData, setComponentData] = useState([{ id: 0, contador: 1 }]);
  const [presupuestoData, setPresupuestoData] = useState(null);
  const [totalDelPresupuesto, setTotalDelPresupuesto] = useState(0);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [isPrinting, setIsPrinting] = useState(false);

  const uniqueRepuestos = repuestos.reduce((accumulator, current) => {
    const exists = accumulator.find((item) => item.tipo === current.tipo);
    if (!exists) {
      accumulator.push(current);
    }
    return accumulator;
  }, []);

  useEffect(() => {
    if (isPrinting) {
      reset();
      setIsPrinting(false);
      setPresupuestoData(null);
      setComponentData([{ id: 0, contador: 1 }]);
      setTotalDelPresupuesto(0);
    }
  }, [isPrinting, reset]);

  useEffect(() => {
    const sumaSubtotales = componentData.reduce((acc, component) => {
      return acc + (component.valorSubTotal || 0) * component.contador;
    }, 0);
    setTotalDelPresupuesto(sumaSubtotales);
  }, [componentData]);

  useEffect(() => {
    if (uniqueRepuestos.length > 0 && isFirstRender) {
      componentData.forEach((component) => {
        setValue(`repuesto${component.id}`, uniqueRepuestos[0].tipo);
      });
      setIsFirstRender(false);
    }
  }, [componentData, setValue, uniqueRepuestos, isFirstRender]);

  const handleNumeroChange = (event, index) => {
    const formateado = currencyMask(event);
    const sinFormato = parseCurrency(formateado);
    setComponentData((prevComponentData) =>
      prevComponentData.map((component, i) =>
        i === index ? { ...component, valorSubTotal: sinFormato } : component
      )
    );
  };

  const parseCurrency = (currency) => {
    if (currency === "") return 0;
    return parseInt(currency.replace(/[.,$]/g, ""), 10);
  };

  const incrementar = (index) => {
    setComponentData((prevComponentData) =>
      prevComponentData.map((component, i) =>
        i === index
          ? { ...component, contador: component.contador + 1 }
          : component
      )
    );
  };

  const disminuir = (index) => {
    setComponentData((prevComponentData) =>
      prevComponentData.map((component, i) =>
        i === index && component.contador > 1
          ? { ...component, contador: component.contador - 1 }
          : component
      )
    );
  };

  const handleInputChangeAll = (event) => {
    const { value } = event.target;
    const newValue = value.toUpperCase();
    event.target.value = newValue;
  };

  const handleInputChangeFirstLetters = (event) => {
    const { value } = event.target;
    const newValue = value.replace(/\b\w/g, (l) => l.toUpperCase());
    event.target.value = newValue;
  };

  const addComponent = () => {
    setComponentData((prevComponentData) => [
      ...prevComponentData,
      { id: prevComponentData.length, contador: 1 },
    ]);
  };

  const deleteComponent = () => {
    setComponentData((prevComponentData) => {
      const newData = [...prevComponentData];
      if (newData.length > 1) {
        newData.splice(newData.length - 1, 1);
      }
      return newData;
    });
  };

  const onSubmit = (data) => {
    const repuestosData = componentData.map((component) => {
      const repuesto = data[`repuesto${component.id}`];
      const accion = data[`accion${component.id}`];
      const cantidadDeRepuesto = component.contador;
      const valorSubTotal = component.valorSubTotal;

      return { repuesto, accion, cantidadDeRepuesto, valorSubTotal };
    });

    const formData = {
      patente: data.patente,
      repuestos: repuestosData,
      titular: data.titular,
      totalDelPresupuesto: totalDelPresupuesto,
      vehiculo: data.vehiculo,
    };
    setPresupuestoData(formData);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="containerPresupuestos">
        <header className="headerPresupuestos">
          <div className="containerImgPresupuestos">
            <img src={iconMarca} alt={iconMarca} />
          </div>
          <div className="containerInfoPresupuestos">
            <div className="containerInfoPrimero">
              <h1>PRESUPUESTO</h1>
              <h3>{fecha.toLocaleDateString()}</h3>
            </div>
            <div className="containerInfoSegundo">
              <div className="valoresInfoSegundo">
                <h3>PATENTE:</h3>
                <input
                  autoComplete="off"
                  className="letterspacing inputPresupuestos"
                  onInput={handleInputChangeAll}
                  {...register("patente", {
                    required: true,
                  })}
                />
              </div>
              {errors.patente?.type === "required" && (
                <p className="requiered">
                  La patente del vehiculo es requerida.
                </p>
              )}
              <div className="valoresInfoSegundo">
                <h3>TITULAR:</h3>
                <input
                  autoComplete="off"
                  className="inputPresupuestos"
                  onInput={handleInputChangeFirstLetters}
                  {...register("titular", {
                    required: true,
                  })}
                />
              </div>
              {errors.titular?.type === "required" && (
                <p className="requiered">El nombre del titular es requerido.</p>
              )}
            </div>
            <div className="containerInfoTercero">
              <div className="containerInfoTercero2">
                <h3>VEHÍCULO:</h3>
                <input
                  autoComplete="off"
                  className="inputPresupuestos"
                  onInput={handleInputChangeFirstLetters}
                  {...register("vehiculo", {
                    required: true,
                  })}
                />
              </div>
              {errors.vehiculo?.type === "required" && (
                <p className="requiered">
                  El nombre del vehiculo es requerido.
                </p>
              )}
            </div>
          </div>
        </header>
        <main className="bodyPresupuestos">
          <div className="containerButtonDeleteRepuesto">
            <FaMinus
              className="buttonDeleteRepuesto"
              onClick={deleteComponent}
            />
          </div>
          <div className="containerButtonAddRepuesto">
            <FcPlus className="buttonAddRepuesto" onClick={addComponent} />
          </div>
          <table className="tablePresupuestos">
            <thead className="theadPresupuestos">
              <tr>
                <th className="thPresupuestosPrimero">DESCRIPCIÓN</th>
                <th className="thPresupuestosSegundo">CANTIDAD</th>
                <th className="thpresupuestosTercero">SUBTOTAL</th>
              </tr>
            </thead>
            <tbody className="tbodyPresupuestos">
              {componentData.map((component, index) => (
                <tr className="trPresupuestos" key={component.id}>
                  <td className="tdPresupuestos selectsPresupuestos">
                    <select
                      {...register(`repuesto${component.id}`, {
                        required: true,
                        defaultValue:
                          uniqueRepuestos.length > 0
                            ? uniqueRepuestos[0].tipo
                            : undefined,
                      })}
                      className="selectPresupuesto"
                    >
                      {uniqueRepuestos.map((repuesto, index) => (
                        <option key={index} value={repuesto.tipo}>
                          {repuesto.tipo}
                        </option>
                      ))}
                    </select>
                    {errors[`repuesto${component.id}`]?.type === "required" && (
                      <p className="requiered">El repuesto es requerido.</p>
                    )}
                    <select
                      {...register(`accion${component.id}`, {
                        required: true,
                      })}
                      className="selectPresupuestoAccion"
                    >
                      <option value="Cambiar">Cambiar</option>
                      <option value="Reparar">Reparar</option>
                      <option value="Pintar">Pintar</option>
                      <option value="Verificar">Verificar</option>
                      <option value="Desmontar y montar">
                        Desmontar y montar
                      </option>
                    </select>
                    {errors[`accion${component.id}`]?.type === "required" && (
                      <p className="requiered">La acción es requerida.</p>
                    )}
                  </td>
                  <td className="tdPresupuestos">
                    <div className="containerContador">
                      <button
                        className="buttonCantidadDeRepuesto"
                        type="button"
                        onClick={() => disminuir(index)}
                      >
                        -
                      </button>
                      {component.contador}
                      <input
                        type="hidden"
                        {...register("cantidadDeRepuesto")}
                      />
                      <button
                        className="buttonCantidadDeRepuesto"
                        type="button"
                        onClick={() => incrementar(index)}
                      >
                        +
                      </button>
                    </div>
                  </td>
                  <td className="tdPresupuestos">
                    <input
                      type="text"
                      className="inputSubTotal"
                      name=""
                      onChange={(e) => handleNumeroChange(e, index)}
                    />
                  </td>
                </tr>
              ))}
              <tr className="trFooterPresupuestos">
                <td className="borderFooterPresupuestos"></td>
                <td className="totalFooterPresupuestos">TOTAL $</td>
                <td className="containerResultadoFooterPresupuestos">
                  <div className="resultadoFooterPresupuestos">
                    {totalDelPresupuesto.toLocaleString("es-AR")}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </main>
        <footer className="footerPresupuestos">
          {presupuestoData === null ? (
            <>
              <button className="containerButtonPresupuestos">
                <div className="containerImgButtonPresupuestos">
                  <img src={impresora} alt="Impresora" />
                </div>
                <p>IMPRIMIR</p>
              </button>
            </>
          ) : (
            <>
              <div className="containerButtonPresupuestos">
                <PDFDownloadLink
                  document={<PdfPresupuestos formData={presupuestoData} />}
                  fileName={`${presupuestoData.patente}.pdf`}
                  className="pdfDowloadLink"
                  onClick={() => setIsPrinting(true)}
                >
                  {({ blob, url, loading, error }) =>
                    loading ? (
                      <div
                        className={`my-button ${loading ? "loading" : ""}`}
                      ></div>
                    ) : (
                      "IMPRIMIR"
                    )
                  }
                </PDFDownloadLink>
              </div>
            </>
          )}
        </footer>
      </form>
    </>
  );
}
