import React from "react";
import {
  Page,
  Text,
  View,
  Image,
  StyleSheet,
  Document,
  Font,
} from "@react-pdf/renderer";
import iconMarca from "../../../Assets/NavBar/iconMarca.png";
import Outfit from "../../../Font/Outfit-VariableFont_wght.ttf";

Font.register({
  family: "Outfit",
  src: Outfit,
});

const styles = StyleSheet.create({
  containerOrdenDeTrabajo: {
    flexDirection: "column",
    width: "100%",
  },
  imageStyle: {
    width: 80,
    height: 80,
    aspectRatio: 1,
  },
  headerOrdenDeTrabajo: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    marginTop: 20,
    paddingHorizontal: 30,
    zIndex: 1,
  },
  containerDatosHeaderOrdenesDeTrabajo: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "80%",
  },
  containerDatosPrimero: {
    flexDirection: "column",
    height: 50,
    justifyContent: "space-between",
    width: "100%",
    //marginRight: "150px",
  },
  containerDatosPrimeroText: {
    margin: 0,
  },
  containerDatosSegundo: {
    flexDirection: "column",
    width: "100%",
    height: 100,
    justifyContent: "space-between",
  },
  datosHeaderPrimero: {
    flexDirection: "row",
    width: "80%",
    marginLeft: "30px",
    marginTop: "10px",
    justifyContent: "space-between",
  },
  smallText: {
    fontSize: "10px",
  },
  backgroundImage: {
    position: "absolute",
    width: "50%",
    opacity: 0.1,
    resizeMode: "contain",
    zIndex: -1,
    top: "25%",
    left: "25%",
  },
  table: {
    display: "table",
    width: "80%",
    marginLeft: "10%",
    marginRight: "10%",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginTop: "50px",
  },
  row: { margin: "auto", flexDirection: "row" },
  cell: {
    fontSize: 12,
    margin: "auto",
    borderBottomWidth: 1,
    borderRightWidth: 1,
    borderColor: "#000",
    borderStyle: "solid",
  },
  header: {
    fontWeight: "bold",
    backgroundColor: "#000",
    color: "#fff",
    textAlign: "center",
  },
  data: {
    textAlign: "center",
    fontSize: 12,
  },
  totalRow: {
    flexDirection: "row",
    width: "80%",
    marginLeft: "10%",
    marginRight: "10%",
  },
  totalText: {
    fontSize: 12,
    fontWeight: "bold",
    textAlign: "right",
    width: "80%",
    borderLeftWidth: 1,
    borderBottomWidth: 1,
    borderRightWidth: 0,
    borderColor: "#000",
    borderStyle: "solid",
    paddingRight: 20,
    paddingTop: 8.5,
  },
  totalValueContainer: {
    width: "20%",
    borderStyle: "solid",
    borderColor: "#000",
    borderWidth: 1,
    borderTopWidth: 0,
    height: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderLeftWidth: 0,
  },
  totalValue: {
    fontSize: 12,
    fontWeight: "bold",
    borderWidth: 1,
    borderColor: "#000",
    borderStyle: "solid",
    height: 50,
    width: "70%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 5,
    marginBottom: 5,
    textAlign: "center",
    paddingTop: 3,
  },
  containerInfoClientes: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginTop: "50px",
  },
  containerDatosAfip: {
    flexDirection: "column",
    height: "100%",
  },
  textAfip: {
    fontSize: "12px",
    marginBottom: "10px",
  },
  titleDatosDeCliente: {
    fontSize: "15px",
    marginLeft: "30px",
  },
  containerFinal: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "30px",
    width: "80%",
    fontSize: "12px",
    marginTop: "30px",
  },
});

export default function PdfPresupuestos({ formData }) {
  const fecha = new Date();

  const totalDelPresupuesto = formData
    ? formData.totalDelPresupuesto.toLocaleString("es-AR")
    : "";

  return (
    <Document>
      <Page size="A4" style={styles.containerOrdenDeTrabajo}>
        <Image src={iconMarca} style={styles.backgroundImage} />
        <View style={styles.headerOrdenDeTrabajo}>
          <Image src={iconMarca} style={styles.imageStyle} />
          <View style={styles.containerDatosHeaderOrdenesDeTrabajo}>
            <View style={styles.containerDatosPrimero}>
              <Text style={styles.containerDatosPrimeroText}>PRESUPUESTO</Text>
              <Text style={styles.containerDatosPrimeroText}>
                {fecha.toLocaleDateString()}
              </Text>
            </View>
            <View style={styles.containerDatosSegundo}>
              <View style={styles.containerDatosAfip}>
                <Text style={styles.textAfip}>Calle 42 N° 1083 e 16 y 17</Text>
                <Text style={styles.textAfip}>La Plata, Buenos Aires</Text>
                <Text style={styles.textAfip}>Tel: 221 - 482 - 1807</Text>
                <Text style={styles.textAfip}>Iva Resp. Inscripto</Text>
                <Text style={styles.textAfip}>Ing. Brutos 30-71471889-0</Text>
                <Text style={styles.textAfip}>CUIT 30-71471889-0</Text>
                <Text style={styles.textAfip}>Email:</Text>
                <Text style={styles.textAfip}>tallerbogo.srl@hotmail.com</Text>
              </View>
            </View>
          </View>
        </View>
        <View style={styles.containerInfoClientes}>
          <Text style={styles.titleDatosDeCliente}>DATOS DEL CLIENTE</Text>
          <View style={styles.datosHeaderPrimero}>
            <Text style={styles.smallText}>
              PATENTE: {formData && formData.patente}
            </Text>
            <Text style={styles.smallText}>
              VEHÍCULO: {formData && formData.vehiculo}
            </Text>
            <Text style={styles.smallText}>
              TITULAR: {formData && formData.titular}
            </Text>
          </View>
        </View>
        <View style={styles.table}>
          <View style={styles.row}>
            <Text style={[styles.cell, styles.header, { width: "45%" }]}>
              DESCRIPCIÓN
            </Text>
            <Text style={[styles.cell, styles.header, { width: "20%" }]}>
              ACCIÓN
            </Text>
            <Text style={[styles.cell, styles.header, { width: "15%" }]}>
              CANTIDAD
            </Text>
            <Text style={[styles.cell, styles.header, { width: "20%" }]}>
              SUBTOTAL
            </Text>
          </View>
          {formData &&
            formData.repuestos.map((item, index) => (
              <View style={styles.row} key={index}>
                <Text style={[styles.cell, styles.data, { width: "45%" }]}>
                  {item.repuesto}
                </Text>
                <Text style={[styles.cell, styles.data, { width: "20%" }]}>
                  {item.accion}
                </Text>
                <Text style={[styles.cell, styles.data, { width: "15%" }]}>
                  {item.cantidadDeRepuesto}
                </Text>
                <Text style={[styles.cell, styles.data, { width: "20%" }]}>
                  {Number(item.valorSubTotal).toLocaleString("es-AR", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}{" "}
                  $
                </Text>
              </View>
            ))}
        </View>
        <View style={styles.totalRow}>
          <Text style={styles.totalText}>TOTAL</Text>
          <View style={styles.totalValueContainer}>
            <Text style={styles.totalValue}>{totalDelPresupuesto} $</Text>
          </View>
        </View>
        <View style={styles.containerFinal}>
          <Text>REQUISITOS: Entregar Cédula Verde.</Text>
          <Text>
            IMPORTANTE: El local no se responsabiliza de objetos personales. Sin
            excepción, por razones de seguridad, se desconecta la batería.
          </Text>
        </View>
      </Page>
    </Document>
  );
}
