import { actionTypes } from "../Constants/ActionTypes";
import { store } from "../Store";


export default function reducerSetTiposDeRepuestos(state = store.tiposDeRepuestos, action){
    switch (action.type) {
        case actionTypes.SET_TIPOS_DE_REPUESTOS:
            let repuestos = action.data
            return Object.assign({}, state, {
            data: repuestos,
            })
        default:
            return state
    }
}