import { actionTypes } from "../Constants/ActionTypes";

const initialState = {
    resPrecioDeLista: null
}

export default function reducerResPrecioDeLista(state = initialState, action){
    switch (action.type) {
      case actionTypes.RES_CAMBIAR_PRECIO_LISTA:
        return {
          ...state,
          resPrecioDeLista: action.data
      }
      case actionTypes.EMPTY_CAMBIAR_PRECIO_LISTA:
        return {
          ...state,
          resPrecioDeLista: null
      }
      default:
        return state;
    }
}