import { actionTypes } from '../Constants/ActionTypes';
import { takeEvery, call, put } from'redux-saga/effects';
import { getTiposDeMarcas } from '../api/getTiposDeMarca';
import { actionSetTiposDeMarcas } from '../Actions/TipoDeMarca';


export const watchTiposDeMarcas = function * (){
    yield takeEvery(actionTypes.GET_TIPOS_DE_MARCAS, fetchTiposDeMarcas)
}

function* fetchTiposDeMarcas(action){
    try{
        const data = yield call(getTiposDeMarcas, action.data)
        if(data){
            yield put(actionSetTiposDeMarcas(data))
        }
    }catch(err){
        console.log(err)
    }
}
