import { useState, useCallback } from "react";

const useCurrencyMaskForItems = (initialValues = {}) => {
  const [values, setValues] = useState(initialValues);

  const currencyMask = useCallback((e, itemId) => {
    let inputValue = e.target.value;
    inputValue = inputValue.replace(/\D/g, "");
    inputValue = inputValue
      .toString()
      .split("")
      .reverse()
      .join("")
      .replace(/(?=\d*\.?)(\d{3})/g, "$1.");
    inputValue = inputValue.split("").reverse().join("").replace(/^[.]/, "");

    setValues(prevValues => ({
      ...prevValues,
      [itemId]: inputValue,
    }));
  }, []);

  return [values, currencyMask];
};

export default useCurrencyMaskForItems;
