import apiInstance from './index';

export const getTiposDeModelos = () => {
    return apiInstance.get('/api/clientes/modelos')
      .then(res => {
        return res.data;
      })
      .catch(error => {
        console.error(error.response);
        throw error.response;
      });
}

