import React from "react";
import {
  Page,
  Text,
  View,
  Image,
  StyleSheet,
  Document,
  Font,
} from "@react-pdf/renderer";
import iconMarca from "../../../Assets/NavBar/iconMarca.png";
import Outfit from "../../../Font/Outfit-VariableFont_wght.ttf";
import { format } from "date-fns";
import { es } from "date-fns/locale";

Font.register({
  family: "Outfit",
  src: Outfit,
});

const styles = StyleSheet.create({
  containerOrdenDeTrabajo: {
    flexDirection: "column",
    width: "100%",
  },
  imageStyle: {
    width: 80,
    height: 80,
    aspectRatio: 1,
    marginRight: "30px",
  },
  headerOrdenDeTrabajo: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%",
    marginTop: 20,
    paddingHorizontal: 30,
    zIndex: 1,
  },
  containerDatosHeaderOrdenesDeTrabajo: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    marginTop: "15px",
    paddingHorizontal: 30,
  },
  containerDatosHeaderOrdenesDeTrabajo2: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    marginTop: "10px",
  },
  containerDatosPrimeroYSegundo: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start", // Asegura que las cajas estén alineadas al inicio
    width: "100%",
  },
  containerDatosTerceroYCuarto: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    marginTop: "30px", // Agregar espacio entre las filas
  },
  containerDatosResultados: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    textAlign: "center",
    width: "100%",
    marginTop: "20px", // Ajusta el espacio entre las etiquetas y los resultados
  },
  datosGroup: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "30%",
  },
  datosGroup2: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  datosGroupCedulaYSeguro: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  datosGroupTerceroYCuarto: {
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    marginRight: "10px",
  },
  datosGroupTerceroYCuartoWidth: {
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    marginRight: "10px",
  },
  containerDatosPrimero: {
    flexDirection: "column",
    height: 50,
    justifyContent: "space-between",
    marginRight: 80,
  },
  containerDatosPrimeroText: {
    margin: 0,
    color: "#FD0001",
    fontFamily: "Outfit",
    fontWeight: "bold",
  },
  containerDatosSegundoText: {
    margin: 0,
    color: "#000",
    fontFamily: "Outfit",
    fontWeight: 500,
  },
  containerDatosSegundo: {
    flexDirection: "column",
    width: "100%",
    height: 60,
    justifyContent: "space-between",
  },
  datosHeaderPrimero: {
    flexDirection: "row",
    width: "40%",
    justifyContent: "space-between",
  },
  datosHeaderSegundo: {
    flexDirection: "row",
  },
  patenteText: {
    marginRight: 150, // Ajusta este valor según la distancia deseada
  },
  smallText: {
    fontSize: 15,
    fontFamily: "Outfit",
    fontWeight: "bold",
    color: "#000",
  },
  smallText2: {
    fontSize: 15,
    fontFamily: "Outfit",
    fontWeight: "bold",
    color: "#000",
    textAlign: "center",
  },
  labelText: {
    fontWeight: "bold",
    fontSize: 12,
    paddingRight: "15px",
  },
  labelText2: {
    fontWeight: "bold",
    fontSize: 12,
  },
  backgroundImage: {
    position: "absolute",
    width: "50%",
    opacity: 0.1, // Ajusta la opacidad según lo desees
    resizeMode: "contain",
    zIndex: -1,
    top: "25%", // Centra la imagen verticalmente
    left: "25%", // Centra la imagen horizontalmente
  },
  containerPago: {
    width: "100%",
    flexDirection: "column",
    marginTop: "30px",
    paddingHorizontal: 30,
  },
  textTitlePago: {
    fontWeight: "bold",
    fontSize: "20px",
    color: "#FD0001",
  },
  containerDatosPago: {
    width: "100%",
    flexDirection: "column",
    marginTop: "30px",
  },
  containerDatosPagoPrimero: {
    flexDirection: "row",
    width: "50%",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: "15px",
  },
  containerDatosPagoSegundo: {
    flexDirection: "row",
    width: "60%",
    alignItems: "center",
    fontSize: "15px",
    justifyContent: "space-between",
    marginTop: "15px",
  },
});

const capitalize = (str) => {
  if (typeof str === 'string') {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }
  return '';
};

export default function PdfOrdenDeCompra({ formData }) {
  const fecha = new Date();
  console.log(formData, "soy dataForm");

  const fechaDeIngresoFormateada =
    formData && formData.fechaDeIngreso
      ? format(new Date(formData.fechaDeIngreso), "dd/MM/yyyy", {
          locale: es,
        })
      : "";

  const fechaDeEgresoFormateada =
    formData && formData.fechaDeEgreso
      ? format(new Date(formData.fechaDeEgreso), "dd/MM/yyyy", {
          locale: es,
        })
      : "";

  const metodoDePago = formData && formData.metodoDePago ? capitalize(formData.metodoDePago) : '';

  const totalFormateado = formData && formData.total
  ? formData.total.toLocaleString('es-AR')
  : '';

  const adeudaFormateado = formData && formData.adeuda > 0
  ? formData.adeuda.toLocaleString('es-AR')
  : '';

  return (
    <Document>
      <Page size="A4" style={styles.containerOrdenDeTrabajo}>
        <Image src={iconMarca} style={styles.backgroundImage} />
        <View style={styles.headerOrdenDeTrabajo}>
          <Image src={iconMarca} style={styles.imageStyle} />
          <View style={styles.containerDatosPrimero}>
            <Text style={styles.containerDatosPrimeroText}>
              ORDEN DE TRABAJO
            </Text>
            <Text style={styles.containerDatosSegundoText}>
              {fecha.toLocaleDateString()}
            </Text>
          </View>
        </View>
        <View style={styles.containerDatosHeaderOrdenesDeTrabajo}>
          <View style={styles.containerDatosPrimeroYSegundo}>
            <View style={styles.datosGroup}>
              <Text style={styles.labelText}>NOMBRE:</Text>
              <Text
                style={[styles.containerDatosPrimeroText, styles.smallText]}
              >
                {formData && formData.nombre}
              </Text>
            </View>
            <View style={styles.datosGroup}>
              <Text style={styles.labelText}>PATENTE:</Text>
              <Text
                style={[styles.containerDatosPrimeroText, styles.smallText]}
              >
                {formData && formData.patente}
              </Text>
            </View>
            <View style={styles.datosGroup}>
              <Text style={styles.labelText}>TELÉFONO:</Text>
              <Text
                style={[styles.containerDatosPrimeroText, styles.smallText]}
              >
                {formData && formData.telefono}
              </Text>
            </View>
          </View>
          <View style={styles.containerDatosHeaderOrdenesDeTrabajo2}>
            <View style={styles.containerDatosTerceroYCuarto}>
              <View style={styles.datosGroupTerceroYCuartoWidth}>
                <Text style={styles.labelText2}>FECHA DE INGRESO</Text>
              </View>
              <View style={styles.datosGroupTerceroYCuartoWidth}>
                <Text style={styles.labelText2}>FECHA DE EGRESO</Text>
              </View>
              <View style={styles.datosGroupTerceroYCuarto}>
                <Text style={styles.labelText2}>CÉDULA VERDE</Text>
              </View>
              <View style={styles.datosGroupTerceroYCuarto}>
                <Text style={styles.labelText2}>SEGURO COBRADO</Text>
              </View>
            </View>
            <View style={styles.containerDatosResultados}>
              <View style={styles.datosGroup2}>
                <Text
                  style={[styles.containerDatosPrimeroText, styles.smallText2]}
                >
                  {formData && fechaDeIngresoFormateada}
                </Text>
              </View>
              <View style={styles.datosGroup2}>
                <Text
                  style={[styles.containerDatosPrimeroText, styles.smallText2]}
                >
                  {formData && fechaDeEgresoFormateada}
                </Text>
              </View>
              <View style={styles.datosGroupCedulaYSeguro}>
                <Text
                  style={[styles.containerDatosPrimeroText, styles.smallText2]}
                >
                  {formData && formData.cedulaVerde === true ? "Si" : "No"}
                </Text>
              </View>
              <View style={styles.datosGroupCedulaYSeguro}>
                <Text
                  style={[styles.containerDatosPrimeroText, styles.smallText2]}
                >
                  {formData && formData.seguroCobrado === true ? "Si" : "No"}
                </Text>
              </View>
            </View>
          </View>
        </View>
        <View style={styles.containerPago}>
          <Text style={styles.textTitlePago}>PAGO</Text>
          <View style={styles.containerDatosPago}>
            <View style={styles.containerDatosPagoPrimero}>
              <Text>TOTAL: {totalFormateado}</Text>
              {formData && formData.adeuda > 0 ? (
                <Text>ADEUDA: {adeudaFormateado}</Text>
              ) : (
                ""
              )}
            </View>
            <View style={styles.containerDatosPagoSegundo}>
            {formData && formData.metodoDePago !== null ?
              <Text>MÉTODO DE PAGO: {metodoDePago}</Text>
              : ""
            }
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
}
