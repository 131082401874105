import { actionTypes } from "../Constants/ActionTypes";

export const actionCambiarPrecioDeLista = (id, precioLista) => {
    return {
        type: actionTypes.PUT_CAMBIAR_PRECIO_LISTA,
        id,
        precioLista
    }
}

export const actionResCambiarPrecioDeLista = (data) => {
    return {
        type: actionTypes.RES_CAMBIAR_PRECIO_LISTA,
        data
    }
}

export const actionEmptyCambiarPrecioDeLista = () => {
    return {
        type: actionTypes.EMPTY_CAMBIAR_PRECIO_LISTA
    }
}