import "./header.css"


export default function Header() {
  
const fecha = new Date()

return (
    <div className="header">
          <div className="title">
            <h1>Juan Bogo e Hijo S.H. - Vehículos</h1>
          </div>
          <div>
            <h2 className="fechaYHora">{fecha.toLocaleDateString()}</h2>
          </div>
    </div>
  )
}
