import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux"
import reducerSetClients from "./reducerSetClients";
import reducerSetNewClient from "./reducerSetNewClient";
import reducerSetRepuestos from "./reducerSetRepuestos";
import reducerSetNewRepuesto from "./reducerSetNewRepuesto";
import reducerSetTiposDeRepuestos from "./reducerSetTiposDeRepuestos";
import reducerSetTiposDeModelos from "./reducerSetTiposDeModelos";
import reducerSetTiposDeMarcas from "./reducerSetTiposDeMarcas";
import reducerResPrecioDeCompra from "./reducerResPrecioDeCompra";
import reducerResPrecioDeLista from "./reducerResPrecioDeLista";

const rootReducer = combineReducers({
    routing: routerReducer,
    reducerSetClients: reducerSetClients,
    reducerSetNewClient: reducerSetNewClient,
    reducerSetRepuestos: reducerSetRepuestos,
    reducerSetNewRepuesto: reducerSetNewRepuesto,
    reducerSetTiposDeRepuestos: reducerSetTiposDeRepuestos,
    reducerSetTiposDeModelos: reducerSetTiposDeModelos,
    reducerSetTiposDeMarcas: reducerSetTiposDeMarcas,
    reducerResPrecioDeCompra: reducerResPrecioDeCompra,
    reducerResPrecioDeLista: reducerResPrecioDeLista,
})

export default rootReducer