import { actionTypes } from '../Constants/ActionTypes';
import { takeEvery, call, put } from'redux-saga/effects';
import { getRepuestos } from '../api/getRepuestos';
import { actionSetRepuestos } from '../Actions/Repuesto';


export const watchGetRepuestos = function * (){
    yield takeEvery(actionTypes.GET_REPUESTOS, fetchGetRepuestos)
}

function* fetchGetRepuestos(action){
    try{
        const data = yield call(getRepuestos, action.data)
        if(data){
            yield put(actionSetRepuestos(data))
        }
    }catch(err){
        console.log(err)
    }
}
