import { actionTypes } from "../Constants/ActionTypes";
import { store } from "../Store";


export default function reducerSetClients(state = store.clients, action){
    switch (action.type) {
        case actionTypes.SET_CLIENTS:
            let clients = action.data
            return Object.assign({}, state, {
            data: clients,
            })
        default:
            return state
    }
}