import apiInstance from './index';

export const getRepuestos = (params) => {
    return apiInstance.get('/api/repuestos',params)
    .then(res =>{
            return res.data;
    })
    .catch(error => {
        console.error(error.response);
        throw error.response
    });
}