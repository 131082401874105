import apiInstance from './index';

export const putCambiarPrecioDeCompra = (id, precioCompra) => {
    return apiInstance.put('/api/repuestos/'+id, {precioCompra: precioCompra})
    .then(res =>{
            return res.data;
    })
    .catch(error => {
        console.error(error.response);
        throw error.response
    });
}