import { actionTypes } from '../Constants/ActionTypes';
import { takeEvery, call, put } from'redux-saga/effects';
import { putCambiarPrecioDeLista } from '../api/putCambiarPrecioDeLista';
import { actionResCambiarPrecioDeLista } from '../Actions/CambiarPrecioLista';


export const watchPutPrecioDeLista = function * (){
    yield takeEvery(actionTypes.PUT_CAMBIAR_PRECIO_LISTA, fetchPutPrecioDeLista)
}

function* fetchPutPrecioDeLista(action){
    try{
        const data = yield call(putCambiarPrecioDeLista, action.id, action.precioLista)
        if(data){
            yield put(actionResCambiarPrecioDeLista(data))
        }
    }catch(err){
        console.log(err)
    }
}
