import { actionTypes } from "../Constants/ActionTypes";

export const actionGetRepuestos = (data) => {
    return {
        type: actionTypes.GET_REPUESTOS,
        data
    }
}

export const actionSetRepuestos = (data) => {
    return {
        type: actionTypes.SET_REPUESTOS,
        data
    }
}

export const addNewRepuesto = (data) => {
    return {
        type: actionTypes.ADD_NEW_REPUESTO,
        data
    }
}
export const setNewRepuesto = (data) => {
    return {
        type: actionTypes.SET_NEW_REPUESTO,
        data
    }
}