import { actionTypes } from "../Constants/ActionTypes";

export const actionGetClients = (data) => {
    return {
        type: actionTypes.GET_CLIENTS,
        data
    }
}

export const actionSetClients = (data) => {
    return {
        type: actionTypes.SET_CLIENTS,
        data
    }
}

export const addNewClient = (data) => {
    return {
        type: actionTypes.ADD_NEW_CLIENT,
        data
    }
}
export const setNewClient = (data) => {
    return {
        type: actionTypes.SET_NEW_CLIENT,
        data
    }
}

