import "./navBar.css"
import iconInicioWhite from "../../Assets/NavBar/iconInicioWhite.svg"
import iconInicioBlack from "../../Assets/NavBar/iconInicioBlack.svg"
import iconClientesWhite from "../../Assets/NavBar/iconClientesWhite.svg"
import iconClientesBlack from "../../Assets/NavBar/iconClientesBlack.svg"
import iconPresupuestoWhite from "../../Assets/NavBar/iconPresupuestoWhite.svg"
import iconPresupuestoBlack from "../../Assets/NavBar/iconPresupuestoBlack.svg"
import iconMarca from "../../Assets/NavBar/iconMarca.png"
import { NavLink } from 'react-router-dom'
import { useState } from "react"

export default function NavBar() {
  const [selectedButton, setSelectedButton] = useState(1);

  const handleClick = (button) => {
    setSelectedButton(button);
};

return (
    
    <>
    <div className='containerNavBar'>
      <div className="containerImgMarca">
        <img src={iconMarca} alt=''/>
      </div>
      <div className='childSidebar'>
        <NavLink onClick={() => handleClick(1)} className={selectedButton === 1 ? 'iconNavLinkTrue' : "iconNavLinkFalse"} to="/8Lcx6g$!9$!TMM9b">
          <div className="containerImg">
            <img src={selectedButton === 1 ? iconInicioBlack : iconInicioWhite} alt='' />
          </div>
          <div>
            <p>Inicio</p>
          </div>
        </NavLink>
        <NavLink onClick={() => handleClick(2)} className={selectedButton === 2 ? 'iconNavLinkTrue' : "iconNavLinkFalse"} to="/8Lcx6g$!9$!TMM9b/repuestos">
          <div className="containerImg">
            <img src={selectedButton === 2 ? iconClientesBlack : iconClientesWhite} alt='' />
          </div>
          <div>
            <p>Repuestos</p>
          </div>
        </NavLink>
        <NavLink onClick={() => handleClick(3)} className={selectedButton === 3 ? 'iconNavLinkTrue' : "iconNavLinkFalse"} to="/8Lcx6g$!9$!TMM9b/presupuesto">
          <div className="containerImg">
            <img src={selectedButton === 3 ? iconPresupuestoBlack : iconPresupuestoWhite} alt='' />
          </div>
          <div>
            <p>Presupuestos</p>
          </div>
        </NavLink>
        <NavLink onClick={() => handleClick(4)} className={selectedButton === 4 ? 'iconNavLinkTrue' : "iconNavLinkFalse"} to="/8Lcx6g$!9$!TMM9b/ordenesDeTrabajo">
          <div className="containerImg">
            <img src={selectedButton === 4 ? iconClientesBlack : iconClientesWhite} alt='' />
          </div>
          <div>
            <p>Ordenes de trabajo</p>
          </div>
        </NavLink>
      </div>
    </div>
    </>
  )
}
