import { actionTypes } from "../Constants/ActionTypes";
import { store } from "../Store";


export default function reducerSetNewClient(state = store.newClient, action){
    switch (action.type) {
        case actionTypes.SET_NEW_CLIENT:
            let client = action.data
            return Object.assign({}, state, {
            data: client,
            })
        default:
            return state
    }
}