import React from "react"
import "./inicio.css"
import Header from "../../Component/Header/Header"
import TableClients from "../../Component/TableClients/TableClients"
import {useSelector} from "react-redux"

export default function Inicio() {
  const clients = useSelector(state => state.reducerSetClients.data)

  const header = ["Patente", "Marca", "Modelo", "Año", "Cliente", "Teléfono", "Email"]

  return (
   
    <>
      <div className="containerInicio">
      <Header/>
        <div className="bodyInicio">
          <TableClients 
            header={header}
            data={clients}
            placeholder={"Búsqueda por patente"}
            filterKey={"patente"}
          />
        </div>
      </div>
    </>
  )
}
