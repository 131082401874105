import { actionTypes } from '../Constants/ActionTypes';
import { takeEvery, call, put } from'redux-saga/effects';
import { getClients } from'../api/getClients';
import { actionSetClients } from '../Actions/Cliente';


export const watchGetClients = function * (){
    yield takeEvery(actionTypes.GET_CLIENTS, fetchGetClients)
}

function* fetchGetClients(action){
    try{
        const data = yield call(getClients, action.data)
        if(data){
            yield put(actionSetClients(data))
        }
    }catch(err){
        console.log(err)
    }
}
