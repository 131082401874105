import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./modalAddNewClient.css";
import { useForm } from "react-hook-form";
import "animate.css";
import { addNewClient } from "../../Redux/Actions/Cliente";
import { FcPlus, FcMinus } from "react-icons/fc";

export default function ModalAddNewClient({ open, setOpen }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm();
  const dispatch = useDispatch();
  const [brandExists, setBrandExists] = useState(false);
  const [modelExists, setModelExists] = useState(false);
  const [loading, setLoading] = useState()
  const [isCreatingNewBrand, setIsCreatingNewBrand] = useState(false);
  const [isCreatingNewModel, setIsCreatingNewModel] = useState(false);
  const resAddNewClient = useSelector(
    (state) => state.reducerSetNewClient.data
  );
  const tipoDeModelo = useSelector(
    (state) => state.reducerSetTiposDeModelos.tiposDeModelo
  );
  const tipoDeMarca = useSelector(
    (state) => state.reducerSetTiposDeMarcas.tiposDeMarca
  );
  const today = new Date();
  const selectedBrand = watch("marca");
  const selectedModel = watch("modelo");

  useEffect(() => {
    if (resAddNewClient.message === "Inserted") {
      reset();
      setLoading(false)
      setOpen(false);
    }
  }, [resAddNewClient, reset, setOpen]);

  useEffect(() => {
    setBrandExists(tipoDeMarca.includes(selectedBrand));
  }, [selectedBrand, tipoDeMarca]);

  useEffect(() => {
    setModelExists(tipoDeModelo.includes(selectedModel));
  }, [selectedModel, tipoDeModelo]);

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const handleInputChangeFirtLetter = (event) => {
    const { value } = event.target;
    const newValue = capitalizeFirstLetter(value);
    event.target.value = newValue;
  };

  const handleInputChangeAll = (event) => {
    const { value } = event.target;
    const newValue = value.toUpperCase();
    event.target.value = newValue;
  };

  const handleInputChangeFirstLetters = (event) => {
    const { value } = event.target;
    const newValue = value.replace(/\b\w/g, (l) => l.toUpperCase());
    event.target.value = newValue;
  };

  const toggleInputBrand = () => {
    setIsCreatingNewBrand(!isCreatingNewBrand);
  };

  const toggleInputModel = () => {
    setIsCreatingNewModel(!isCreatingNewModel);
  };

  const onSubmit = (data) => {
    const formattedDate = `${today.getDate().toString().padStart(2, "0")}/${(
      today.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}/${today.getFullYear()}`;
    const newData = { ...data, fecha: formattedDate };
    setLoading(true)
    dispatch(addNewClient(newData));
  };

  return (
    <>
      {open && (
        <div className={`modal ${open ? "is-active" : ""}`}>
          <div className="modal-card animate__jackInTheBox">
            <header className="modal-card-head">
              <p className="modal-card-title">Añadir nuevo cliente</p>
              <button
                className="close-button "
                onClick={() => setOpen(false)}
              ></button>
            </header>
            <section className="modal-card-body">
              <form onSubmit={handleSubmit(onSubmit)} className="form">
                <div className="containerModalRow">
                  <div className="form-group">
                    <label className="label">Nombre del cliente</label>
                    <input
                      className="input"
                      name="nombreCliente"
                      type="text"
                      onInput={handleInputChangeFirstLetters}
                      {...register("nombreCliente", {
                        required: true,
                      })}
                    />
                    {errors.nombreCliente?.type === "required" && (
                      <p className="requiered">
                        El nombre del cliente es requerido.
                      </p>
                    )}
                  </div>

                  <div className="form-group">
                    <label className="label">Apellido del cliente</label>
                    <input
                      className="input"
                      name="apellidoCliente"
                      type="text"
                      onInput={handleInputChangeFirstLetters}
                      {...register("apellidoCliente", {
                        required: true,
                      })}
                    />
                    {errors.apellidoCliente?.type === "required" && (
                      <p className="requiered">
                        El apellido del cliente es requerido.
                      </p>
                    )}
                  </div>
                </div>

                <div className="containerModalRow">
                  <div className="form-group">
                    <label className="label">Patente</label>
                    <input
                      className="input letterspacing"
                      type="text"
                      name="patente"
                      onInput={handleInputChangeAll}
                      {...register("patente", {
                        required: true,
                      })}
                    />
                    {errors.patente?.type === "required" && (
                      <p className="requiered">
                        La patente del vehiculo es requerida.
                      </p>
                    )}
                  </div>

                  <div className="form-group">
                    <label className="label">Marca</label>
                    <div className="containerMarcaDeVehiculo">
                      {isCreatingNewBrand ? (
                        <input
                          className="input selectModalAddNewClient"
                          name="marca"
                          type="text"
                          {...register("marca", { required: true })}
                        />
                      ) : (
                        <select
                          className="selectModalAddNewClient"
                          name="marca"
                          {...register("marca", { required: true })}
                        >
                          <option value="">Selecciona una marca</option>
                          {tipoDeMarca.map((brand) => (
                            <option key={brand} value={brand}>
                              {brand}
                            </option>
                          ))}
                        </select>
                      )}
                      {isCreatingNewBrand ? (
                        <div className="tooltip">
                          <FcMinus
                            onClick={toggleInputBrand}
                            className="fcMinus"
                          />
                          <span className="tooltip-text">
                            Seleccionar marca existente
                          </span>
                        </div>
                      ) : (
                        <div className="tooltip">
                          <FcPlus
                            onClick={toggleInputBrand}
                            className="fcMinus"
                          />
                          <span className="tooltip-text">
                            Agregar nueva marca
                          </span>
                        </div>
                      )}
                      {errors.marca?.type === "required" && (
                        <p className="requiered">La marca es requerida.</p>
                      )}
                    </div>

                    {brandExists && isCreatingNewBrand && (
                      <p className="requiered">La marca ya existe, selecciona otra.</p>
                    )}
                  </div>
                </div>
                <div className="containerModalRow">
                  <div className="form-group">
                    <label className="label">Modelo</label>
                    <div className="containerModeloDeVehiculo">
                      {isCreatingNewModel ? (
                        <input
                          className="input selectModalAddNewClient"
                          name="modelo"
                          type="text"
                          {...register("modelo", { required: true })}
                        />
                      ) : (
                        <select
                          className="selectModalAddNewClient"
                          name="modelo"
                          {...register("modelo", { required: true })}
                        >
                          <option value="">Selecciona un modelo</option>
                          {tipoDeModelo.map((model) => (
                            <option key={model} value={model}>
                              {model}
                            </option>
                          ))}
                        </select>
                      )}
                      {isCreatingNewModel ? (
                        <div className="tooltip">
                          <FcMinus
                            onClick={toggleInputModel}
                            className="fcMinus"
                          />
                          <span className="tooltip-text">
                            Seleccionar modelo existente
                          </span>
                        </div>
                      ) : (
                        <div className="tooltip">
                          <FcPlus
                            onClick={toggleInputModel}
                            className="fcMinus"
                          />
                          <span className="tooltip-text">
                            Agregar nuevo modelo
                          </span>
                        </div>
                      )}
                      {errors.modelo?.type === "required" && (
                        <p className="requiered">El modelo es requerido.</p>
                      )}
                    </div>

                    {modelExists && isCreatingNewModel && (
                      <p className="requiered">El modelo ya existe, selecciona otro.</p>
                    )}
                  </div>
                  <div className="form-group">
                    <label className="label">Año</label>
                    <input
                      className="input letterspacing "
                      onInput={handleInputChangeAll}
                      onWheel={(e) => e.target.blur()}
                      type="number"
                      name="año"
                      {...register("año", {
                        required: true,
                      })}
                    />
                    {errors.año?.type === "required" && (
                      <p className="requiered">
                        El año del vehiculo es requerido.
                      </p>
                    )}
                  </div>
                </div>
                <div className="containerModalRow">
                  <div className="form-group">
                    <label className="label">Teléfono</label>
                    <input
                      className="input"
                      name="telefono"
                      onWheel={(e) => e.target.blur()}
                      type="number"
                      onInput={handleInputChangeAll}
                      {...register("telefono", {
                        required: true,
                      })}
                    />
                    {errors.telefono?.type === "required" && (
                      <p className="requiered">
                        El teléfono del cliente es requerido.
                      </p>
                    )}
                  </div>

                  <div className="form-group">
                    <label className="label">Email</label>
                    <input
                      className="input"
                      name="email"
                      type="text"
                      {...register("email", {
                        required: true,
                        pattern:
                          /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/,
                      })}
                    />
                    {errors.email?.type === "required" && (
                      <p className="requiered">
                        El email del cliente es requerido.
                      </p>
                    )}
                    {errors.email?.type === "pattern" && (
                                    <p className='requiered'>Formato de email incorrecto</p>
                      )}
                  </div>
                </div>
                <footer className="modal-card-foot">
                  <div className="containerButtons">
                    {loading ? 
                    <div className={`my-button ${loading ? 'loading' : ''}`}></div>
                    :
                    <button className="my-button">Guardar</button>
                    }
                    <div
                      className="cancel-button"
                      onClick={() => setOpen(false)}
                    >
                      Cancelar
                    </div>
                  </div>
                </footer>
              </form>
            </section>
          </div>
        </div>
      )}
    </>
  );
}
