import { actionTypes } from '../Constants/ActionTypes';
import { takeEvery, call, put } from'redux-saga/effects';
import { postAddNewRepuesto } from '../api/postAddNewRepuesto';
import { setNewRepuesto } from '../Actions/Repuesto';


export const watchAddNewRepuesto = function * (){
    yield takeEvery(actionTypes.ADD_NEW_REPUESTO, fetchAddNewRepuesto)
}

function* fetchAddNewRepuesto(action){
    try{
        const data = yield call(postAddNewRepuesto, action.data)
        if(data){
            yield put(setNewRepuesto(data))
        }
    }catch(err){
        console.log(err)
    }
}
