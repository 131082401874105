import { actionTypes } from '../Constants/ActionTypes';
import { takeEvery, call, put } from'redux-saga/effects';
import { getTiposDeRepuestos } from '../api/getTiposDeRepuestos';
import { actionSetTiposDeRepuestos } from '../Actions/TipoDeRepuesto';


export const watchTiposDeRepuestos = function * (){
    yield takeEvery(actionTypes.GET_TIPOS_DE_REPUESTOS, fetchTiposDeRepuestos)
}

function* fetchTiposDeRepuestos(action){
    try{
        const data = yield call(getTiposDeRepuestos, action.data)
        if(data){
            yield put(actionSetTiposDeRepuestos(data))
        }
    }catch(err){
        console.log(err)
    }
}
