import { actionTypes } from "../Constants/ActionTypes";

const initialState = {
    tiposDeModelo: []
}

export default function reducerSetTiposDeModelos(state = initialState, action){
    switch (action.type) {
      case actionTypes.SET_TIPOS_DE_MODELOS:
        return {
          ...state,
          tiposDeModelo: action.data
      }
      default:
        return state;
    }
}