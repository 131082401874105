import { actionTypes } from '../Constants/ActionTypes';
import { takeEvery, call, put } from'redux-saga/effects';
import { getTiposDeModelos } from '../api/getTiposDeModelos';
import { actionSetTiposDeModelos } from '../Actions/TipoDeModelo';


export const watchTiposDeModelos = function * (){
    yield takeEvery(actionTypes.GET_TIPOS_DE_MODELOS, fetchTiposDeModelos)
}

function* fetchTiposDeModelos(action){
    try{
        const data = yield call(getTiposDeModelos, action.data)
        if(data){
            yield put(actionSetTiposDeModelos(data))
        }
    }catch(err){
        console.log(err)
    }
}
