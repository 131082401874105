import React, { useEffect, useState } from "react";
import "./ordenesDeTrabajo.css";
import { useForm } from "react-hook-form";
import InputOrdenesDeTrabajo from "../../Component/InputOrdenesDeTrabajo/InputOrdenesDeTrabajo";
import { PDFDownloadLink } from "@react-pdf/renderer";
import impresora from "../../Assets/Presupuestos/Impresora.png";
import useCurrencyMask from "../../Hooks/useCurrencyMask";
import PdfOrdenDeCompra from "./PdfOrdenesDeCompra/PdfOrdenDeCompra";

export default function OrdenesDeTrabajo() {
  const currencyMask = useCurrencyMask();
  const [inputValues, setInputValues] = useState({ total: 0, adeuda: 0 });
  const [presupuestoData, setPresupuestoData] = useState(null);
  const [pagoConTarjeta, setPagoConTarjeta] = useState(false);
  const [isPrinting, setIsPrinting] = useState(false);
  const {
    register,
    control,
    handleSubmit,
    watch,
    reset,
  } = useForm();

  const metodoDePago = watch("metodoDePago", "");
  
  useEffect(() => {
    if (metodoDePago === 'pagoConTarjeta') {
      setPagoConTarjeta(true);
    } else {
      setPagoConTarjeta(false);
    }
  }, [metodoDePago]);

  useEffect(() => {
    if (isPrinting) {
      reset();
      setIsPrinting(false);
      setPresupuestoData(null);
    }
  }, [isPrinting, reset]);


  const handleInput = (event) => {
    event.target.value = event.target.value.toUpperCase();
  };

  const handleNumeroChange = (event, index) => {
    const formateado = currencyMask(event);
    const sinFormato = parseCurrency(formateado);
    const { name } = event.target;

    setInputValues((prevState) => ({
      ...prevState,
      [name]: sinFormato,
    }));
  };
  
  const parseCurrency = (currency) => {
    if (currency === "") return 0;
    return parseInt(currency.replace(/[.,$]/g, ""), 10);
  };

  const onSubmit = (data) => {
    const formData = {
      ...data,
      total: inputValues.total,
      adeuda: inputValues.adeuda,
    };
    setPresupuestoData(formData);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="containerOrdenesDeTrabajo">
        <header>
          <h1 className="titleOrdenesDeTrabajo">ORDENES DE TRABAJO</h1>
          <InputOrdenesDeTrabajo
            label="NOMBRE"
            name="nombre"
            register={register}
            control={control}
            datePickerLabel="FECHA DE INGRESO"
            option="CEDULA VERDE"
            optionSubmit="cedulaVerde"
            type="text"
            datePickerName="fechaDeIngreso"
          />
          <InputOrdenesDeTrabajo
            label="TELÉFONO"
            name="telefono"
            register={register}
            control={control}
            datePickerLabel="FECHA DE EGRESO"
            option="SEGURO COBRADO"
            optionSubmit="seguroCobrado"
            type="number"
            datePickerName="fechaDeEgreso"
          />
          <div className="firstInput">
            <p>PATENTE</p>
            <input {...register("patente")} className="inputFirst" type="text" onInput={handleInput} />
          </div>
        </header>
        <main>
          <h1 className="titleOrdenesDeTrabajo">PAGOS</h1>
          <div className="containerFirstMain">
            <div className="containerInputMain">
              <p>TOTAL</p>
              <input
                type="text"
                className="inputMain"
                name="total"
                onChange={(e) => handleNumeroChange(e)}
              />
              <p className="pesos">$</p>
            </div>
            <div className="containerInputMain">
              <p>ADEUDA</p>
              <input
                type="text"
                className="inputMain"
                name="adeuda"
                onChange={(e) => handleNumeroChange(e)}
              />
              <p className="pesos">$</p>
            </div>
          </div>
          <div className="containerSecondMain">
            <div className="secondInput">
              <p>PAGO EN EFECTIVO</p>
              <input
                className="inputRadio"
                type="radio"
                name="metodoDePago"
                value="efectivo"
                {...register("metodoDePago")}
              />
            </div>
            <div className="pagoConTarjeta">
              <p>PAGO CON TARJETA</p>
              <input
                className="inputRadio"
                type="radio"
                name="metodoDePago"
                value="tarjeta"
                {...register("metodoDePago")}
              />
            </div>
          </div>
        </main>
        <footer className="footerPresupuestos">
          {presupuestoData === null ? (
            <>
              <button
                className="containerButtonPresupuestos"
              >
                <div className="containerImgButtonPresupuestos">
                  <img src={impresora} alt="Impresora" />
                </div>
                <p>IMPRIMIR</p> 
              </button>
            </>
          ) : (
            <>
              <div className="containerButtonPresupuestos">
                <PDFDownloadLink
                  document={<PdfOrdenDeCompra formData={presupuestoData} />}
                  fileName="OrdenDeTrabajoTallerBogo.pdf"
                  className="pdfDowloadLink"
                  onClick={() => setIsPrinting(true)}
                >
                  {({ blob, url, loading, error }) =>
                    loading ? (
                      <div className={`my-button ${loading ? 'loading' : ''}`}>
                      </div>
                    ) : (
                      "IMPRIMIR"
                    )
                  }
                </PDFDownloadLink>
              </div>
            </>
          )}
        </footer>
      </form>
    </>
  );
}
