import { useState, useCallback } from "react";

const useCurrencyMaskForText = (initialValue = "") => {
  const [value, setValue] = useState(initialValue);

  const currencyMask = useCallback((e) => {
    let inputValue = e.target.value;
    inputValue = inputValue.replace(/\D/g, "");
    inputValue = inputValue
      .toString()
      .split("")
      .reverse()
      .join("")
      .replace(/(?=\d*\.?)(\d{3})/g, "$1.");
    inputValue = inputValue.split("").reverse().join("").replace(/^[.]/, "");

    setValue(inputValue);
  }, []);

  return [value, currencyMask];
};

export default useCurrencyMaskForText;