import { actionTypes } from "../Constants/ActionTypes";


export const actionGetTiposDeModelos = () => {
    return {
        type: actionTypes.GET_TIPOS_DE_MODELOS
    }
}

export const actionSetTiposDeModelos = (data) => {
    return {
        type: actionTypes.SET_TIPOS_DE_MODELOS,
        data
    }
}