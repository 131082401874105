import "./buttonAdd.css"
//import Add from "../../Assets/ButtonAdd/addIcon.svg"

export default function ButtonAdd({content, onClick}) {
  
  
return (
    
    <div onClick={onClick} className="containerButtonAddCar">
        <button className="buttonAddCar">{content}</button>
        {/* <img className="imageAdd" src={Add} alt={Add}/> */}
    </div>
  )
}
