import { actionTypes } from "../Constants/ActionTypes";

export const actionGetTiposDeRepuestos = () => {
    return {
        type: actionTypes.GET_TIPOS_DE_REPUESTOS
    }
}

export const actionSetTiposDeRepuestos = (data) => {
    return {
        type: actionTypes.SET_TIPOS_DE_REPUESTOS,
        data
    }
}