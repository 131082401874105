import React from "react";
import "./inputOrdenesDeTrabajo.css";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker, { registerLocale } from "react-datepicker";
import { Controller } from "react-hook-form";
import es from 'date-fns/locale/es'

export default function InputOrdenesDeTrabajo({
  label,
  register,
  name,
  datePickerLabel,
  control,
  option,
  type,
  datePickerName,
  optionSubmit,
}) {
  registerLocale("es", es)
  return (
    <div className="containerInputOrdenesDeTrabajo">
      <div className="firstInput">
        <p>{label}</p>
        <input
        className="inputFirst"
         {...register(name)} type={type}
         onWheel={(e) => e.target.blur()}
         />
      </div>
      <div className="containerFechasInputOrdenesDeTrabajo">
        <p>{datePickerLabel}</p>
        <Controller
          control={control}
          name={datePickerName}
          render={({ field }) => (
            <DatePicker
                showIcon
              selected={field.value}
              onChange={(date) => field.onChange(date)}
              dateFormat="dd/MM/yyyy"
              placeholderText="DD/MM/AAAA"
              locale="es"
            />
          )}
        />
      </div>
      <div className="secondInput">
        <p >{option}</p>
        <input
          className="inputCheckBox"
          type="checkbox"
          {...register(optionSubmit)}
        />
      </div>
    </div>
  );
}
